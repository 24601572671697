<template>
  <div class="wrapper">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="left" label-width="130px" class="demo-ruleForm">
      <el-form-item label="审核状态" prop="auditstatus">
          <el-radio-group v-model="ruleForm.auditstatus" prop="auditstatus">
            <el-radio label="1">通过</el-radio>
            <el-radio label="2">不通过</el-radio>
          </el-radio-group>
      </el-form-item>
      <el-form-item label="审核描述" prop="auditdesc">
        <el-input type="textarea" v-model="ruleForm.auditdesc"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">审核</el-button>
        <el-button @click="cancelFn">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { examinePointTwice } from '@/api/examinePoint/examinePoint.js'
export default {
  name:"auditpoint",
  components:{},
  props:{
    pointrecordid: {
      type: Number
    }
  },
  data(){
    return {
      ruleForm: {
        auditstatus: null,
        auditdesc: '',
      },
      rules: {
        auditstatus: [
          { required: true, message: '请选择审核状态', trigger: 'change' },
        ],
        auditdesc: [
          { required: true, message: '请输入审核描述', trigger: 'change' },
        ],
      }
    }
  },
  watch:{},
  created(){},
  mounted(){},
  computed:{},
  methods:{
    //取消添加
    cancelFn(type){
      this.$refs.ruleForm.resetFields();
      if(type){
        this.$emit('closeAuditPointDialog',type)
      }else{
        this.$emit('closeAuditPointDialog')
      }
    },
    //重置表单
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    //确认添加
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否确认审核积分?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            examinePointTwice({
              pointrecordid: this.pointrecordid,
              auditsecstatus: this.ruleForm.auditstatus,
              auditsecdesc: this.ruleForm.auditdesc
            }).then( res => {
              if(res.result == 200){
                this.$message.success('审核成功');
                this.cancelFn('add');
              }else{
                this.$message.error(res.description)
              }
            })
          }).catch(() => {

          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>

</style>